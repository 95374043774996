export const globalConcerts = [
  {
    id: 1001,
    name: "FNC BAND KINGDOM 2024 - 일본",
    location: "지바 마쿠하리 멧세 마쿠하리 이벤트 홀",
    date: ["2024-07-13(토)", "2024-07-14(일)"],
    startTime: "16:00",
    durationMinutes: 270,
    type: "콘서트",
    performanceType: "출연",
    artists: ["N.Flying", "FTISLAND", "CNBLUE", "Hi-Fi Un!corn"],
    poster: "/image/show/world/240713_BAND_KINGDOM_2024_JP.png",
    ticketOpen: { date: "2024-06-29", time: "10:00" },
    ticketLink: "https://nflying-official.jp/news/detail/698",
    lat: "35.6482438",
    lng: "140.0321482",
  },
  {
    id: 1002,
    name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN : 요코하마",
    location: "KT Zepp Yokohama",
    date: ["2024-10-29(화)"],
    startTime: "19:00",
    durationMinutes: 120,
    type: "콘서트",
    performanceType: "단독",
    artists: ["N.Flying"],
    poster: "/image/show/world/241029-241101_HIDE-OUT_JP.png",
    ticketOpen: { date: "2024-10-11", time: "19:00" },
    ticketLink: "https://nflying-official.jp/news/detail/731",
    lat: "35.4598951",
    lng: "139.6260000",
  },
  {
    id: 1003,
    name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN : 나고야",
    location: "Nagoya Bottom Line",
    date: ["2024-10-31(목)"],
    startTime: "19:00",
    durationMinutes: 120,
    type: "콘서트",
    performanceType: "단독",
    artists: ["N.Flying"],
    poster: "/image/show/world/241029-241101_HIDE-OUT_JP.png",
    ticketOpen: { date: "2024-10-11", time: "19:00" },
    ticketLink: "https://nflying-official.jp/news/detail/731",
    lat: "35.1700072",
    lng: "136.93860476",
  },
  {
    id: 1004,
    name: "2024 N.Flying LIVE HIDE-OUT IN JAPAN : 오사카",
    location: "Zepp Namba",
    date: ["2024-11-01(금)"],
    startTime: "19:00",
    durationMinutes: 120,
    type: "콘서트",
    performanceType: "단독",
    artists: ["N.Flying"],
    poster: "/image/show/world/241029-241101_HIDE-OUT_JP.png",
    ticketOpen: { date: "2024-10-11", time: "19:00" },
    ticketLink: "https://nflying-official.jp/news/detail/731",
    lat: "34.6575657",
    lng: "135.501331",
  },
  {
    id: 1005,
    name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA : 방콕",
    location: "MCC HALL BANGKAE",
    date: ["2024-11-23(토)"],
    startTime: "18:00",
    durationMinutes: 120,
    type: "콘서트",
    performanceType: "단독",
    artists: ["N.Flying"],
    poster: "/image/show/world/241123_HIDE-OUT_BANKOK.jpg",
    ticketOpen: { date: "2024-10-05", time: "14:00" }, // 현지시각 12시 (시차 +2h)
    ticketLink: "https://www.thaiticketmajor.com/concert/2024-nflying-live-hide-out-in-bangkok.html?utm_source=intro&utm_medium=banner&utm_campaign=NFlying2024BKK",
    lat: "13.71440298",
    lng: "100.4068519",
  },
  {
    id: 1006,
    name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA : 쿠알라룸푸르",
    location: "ZEPP KUALA LUMPUR",
    date: ["2024-12-01(일)"],
    startTime: "18:00",
    durationMinutes: 120,
    type: "콘서트",
    performanceType: "단독",
    artists: ["N.Flying"],
    poster: "/image/show/world/241201_HIDE-OUT_MY.jpg",
    ticketOpen: { date: "2024-09-27", time: "12:00" }, // 현지시각 11시 (시차 +1h)
    ticketLink: "https://my.bookmyshow.com/events/2024-n.flying-live-‘hide-out’-in-kuala-lumpur/NFKL2024",
    lat: "3.1397000",
    lng: "101.7093000",
  },
  {
    id: 1007,
    name: "2024 FNC KINGDOM - SING SING SING : 일본",
    location: "마쿠하리 멧세 국제 전시장 9-10홀",
    date: ["2024-12-14(토)", "2024-12-15(일)"],
    startTime: "16:00",
    durationMinutes: 120,
    type: "콘서트",
    performanceType: "출연",
    artists: ["N.Flying"],
    poster: "/image/show/world/241214_BAND_KINGDOM_SING3_JP.png",
    ticketOpen: { date: "2024-08-01", time: "18:00" },
    ticketLink: "https://nflying-official.jp/news/detail/728", // todo 일반티켓 오픈 시 변경
    lat: "35.6500363",
    lng: "140.0365264",
  },
  {
    id: 1008,
    name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA : 마카오",
    location: "Broadway Theatre, Broadway Macau",
    date: ["2024-12-22(일)"],
    startTime: "19:00",
    durationMinutes: 120,
    type: "콘서트",
    performanceType: "단독",
    artists: ["N.Flying"],
    poster: "/image/show/world/241222_HIDE-OUT_MACAU.jpeg",
    ticketOpen: { date: "2024-10-29", time: "13:00" }, // 현지시각 12시 (시차 +1h)
    ticketLink: "https://fncent.com/?m=bbs&bid=notice&p=2&where=site%3D1+and+notice%3D0+and+bbsid%3D'notice'++and+ex1%3D'1'+&uid=60294",
    // todo 예매 페이지 오픈 시 변경
    lat: "22.14708",
    lng: "113.5520586",
  },
  {
    id: 1009,
    name: "1st FNC BAND KINGDOM in TAOYUAN : 대만",
    location: "THE TAOYUAN ARENA",
    date: ["2025-01-04(토)", "2025-01-05(일)"],
    startTime: "15:30",
    durationMinutes: 0, // todo 공연 시간 미정
    type: "콘서트",
    performanceType: "출연",
    artists: ["N.Flying", "FTISLAND", "CNBLUE", "Hi-Fi Un!corn"],
    poster: "/image/show/world/250104_1st_KINGDOM_TAOYUAN.png",
    ticketOpen: { date: "2024-08-24", time: "14:00" }, // 현지시각 13시 (시차 +1h)
    ticketLink: "https://ticketplus.com.tw/activity/f828edd61c11359cc3f3b991e8bcb4d5",
    lat: "24.9934617",
    lng: "121.3238273",
  },
  { // todo 현재 공연 상세 정보 미정
    id: 1010,
    name: "2024 N.Flying LIVE 'HIDE-OUT' in ASIA : 대만",
    location: "미정",
    date: ["2025-02-09(일)"],
    startTime: "00:00",
    durationMinutes: 0,
    type: "콘서트",
    performanceType: "단독",
    artists: ["N.Flying"],
    poster: "/image/show/world/250209_HIDE-OUT_TAIPEI_TEMP.jpg", // todo 실제 포스터로 변경
    ticketOpen: { date: "0000-00-00", time: "00:00" },
    ticketLink: "",
    lat: "37.55107", // todo 공연장 좌표로 수정 필요 (현재 fnc)
    lng: "127.04980",
  },
];
